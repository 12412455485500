html {
    overflow-x: hidden;
    font-family: "Yantramanav", sans-serif;
    font-family: "Poppins", sans-serif;
}

body {
    overflow-x: hidden;
    font-family: "Yantramanav", sans-serif;
    font-family: "Poppins", sans-serif;
    background-image: url(./Images/BG2.avif);
    background-repeat: no-repeat;
    background-size:cover;
}