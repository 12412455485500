html {
  margin: 0px;
  padding: 0px;
}

*::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  cursor: pointer !important;
  width: 5px;
}

::-webkit-scrollbar-button {
  background-color: rgb(0, 166, 255) !important;
  border-radius: 50px;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0, 195, 255) !important;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255) !important;
  box-shadow: inset 0px 0px 8px black;
}

.less1000px {
  display: block;
}

.showHptl {
  display: block;
}

.show750px {
  display: none;
}

.hidehspital {
  display: none;
}

.galleryHover {
  transform: scale(0.98);
  opacity: 0.95;
  transition: .3s ease-in-out;
}

.Loading {
  cursor: progress;
  background: rgba(191, 194, 196, 0.677);
  animation: animate 1s infinite linear;
}

@keyframes animate {
  0% {
    background: rgba(191, 194, 196, 0.677);
  }

  50% {
    background: rgb(213, 214, 215);
  }

  100% {
    background: rgba(191, 194, 196, 0.677);
  }
}

.galleryHover:hover {
  opacity: 1;
  transform: scale(1);
  cursor: pointer;
  /* border-radius: 20px; */
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.789);
}

@media screen and (max-width:1000px) {
  .less1000px {
    display: none;
  }

  .showHptl {
    display: none;
  }

  .hidehspital {
    display: block;
  }

  .show750px {
    display: block;
  }
}

.less450px {
  display: block;
}

.show450px {
  display: none;
}

@media screen and (max-width:550px) {
  .less450px {
    display: none;
  }

  .show450px {
    display: block;
  }
}

.hoverimg {
  transition: 0.3s ease-in-out !important;
}

.hoverimg:hover {
  transform: scale(1.1) !important;
}