.EnvInfo {
    background-image: url(../Images/EnvInfoBG.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.whyChooseUsBG {
    background-image: url(../Images/EnvInfoBG.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.hoverContact {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    border: 2px solid rgba(0, 140, 255, 0);
    transition: .3s ease-in-out;
    transform: scale(0.98);
    border-radius: 0px;
}

.hoverContact:hover {
    transform: scale(1.01);
    box-shadow: 0px 2px 2px 1px rgb(59, 83, 95);
    border: 2px solid rgba(0, 140, 255, 0.818);
    background: rgba(0, 0, 0, 0.775);
    color: white;
    cursor: pointer;
    /* border-bottom-right-radius: 15px; */
    /* border-top-left-radius: 15px; */
}


.handleImage,
.handleImage2,
.handleImage3,
.handleImage4 {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
    transform: scale(0.99);
    transition: .3s ease-in-out;
    cursor: pointer;
}

.handleImage {
    transform: rotate(0deg);
    max-width: 1200px;
}

.handleImage2 {
    transform: rotate(-2deg);
    max-width: 400px;
}

.handleImage3 {
    transform: rotate(2deg);
    max-width: 400px;
}

.handleImage:hover,
.handleImage2:hover,
.handleImage3:hover {
    box-shadow: 5px 5px 10px 0px black;
    transform: scale(1.02);
    animation: zoomingEffect linear;
}

.handleImage4:hover {
    box-shadow: 0px 0px 15px 5px rgb(255, 255, 255);
    transform: scale(1.02);
    animation: zoomingEffect linear;
}

@keyframes zoomingEffect {
    0% {
        transform: rotate(-12deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.handleHospitalImage {
    transition: .3s ease-in-out;
    cursor: pointer;
}

.handleHospitalImage:hover {
    box-shadow: 0px 5px 15px 1px rgb(0, 0, 0);
    transform: scale(1.02) translateY(10px);
    animation: effectHost linear;
}