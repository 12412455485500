.applyPNFcss {
    /* background-color: red; */
    position: absolute !important;
    animation: alternate;
    animation: zoomInOut 6s infinite linear;
    filter: drop-shadow(0px 5px 2px rgb(0, 0, 0));
}

@keyframes zoomInOut {
    0% {
        transform: scale(0.5) rotate(20deg);
        
    }
    
    50% {
        transform: scale(4.5) rotate(-20deg);
    }
    100% {
        transform: scale(0.5) rotate(20deg);
    }
}

.applyPNFcssDiv {
    background: linear-gradient(60deg, rgb(59, 181, 246), rgba(68, 101, 199, 0.809), rgb(0, 242, 255));
    animation: rotation 6s infinite linear;
    box-shadow: 0px 0px 100px 20px rgba(255, 255, 255, 0.559);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg) scale();
    }
    
    50% {
        transform:rotate(180deg) scale(1.5);
    }
    100% {
        transform:rotate(360deg) scale();
    }
}