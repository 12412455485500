.image-scale {
    animation: scaleIn 7s forwards;
}

@keyframes scaleIn {
    from {
        opacity: 0.5;
        transform: scale(1);
    }
    to {
        opacity: 1;
        transform: scale(1.2);
    }
}
