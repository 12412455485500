.btnStyling {
    border-bottom: 2px solid transparent;
    transition: .5s ease-in-out;
}

.btnStyling:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
    background-color: rgb(63, 169, 249);
    color: rgb(0, 0, 0);
}

/* Supportive Nav */
.supportiveNavBG {
    /* background: linear-gradient(90deg, rgb(62, 160, 226), rgb(62, 160, 226), rgb(62, 160, 226)); */
    background: white;
    font-weight: bolder;
    letter-spacing: 2px;
}

.btnStylingSupportiveNav {
    border-bottom: 2px solid transparent;
    transition: .5s ease-in-out;
    color: black;
}

.btnStylingSupportiveNav:hover {
    border-bottom: 2px solid black;
}

.btnStylingSupportiveNav:hover .bi-instagram {
    color: rgb(164, 27, 176);
    font-weight: 800;
}

.btnStylingSupportiveNav:hover .bi-facebook {
    color: rgb(20, 0, 170);
    font-weight: 800;
}

.btnStylingSupportiveNav:hover .bi-twitter {
    color: rgb(20, 0, 170);
    font-weight: 800;
}

/* Supportive Nav */

#navLinks a:active {
    color: red !important;
}


#navbar a {
    border-bottom: 1px solid rgba(129, 194, 254, 0) !important;
    padding: 0px !important;
}

#navbar a.active {
    color: rgb(129, 194, 254) !important;
    border-bottom: 1px solid rgb(129, 194, 254) !important
}

#navbar a:hover {
    color: rgb(96, 237, 110) !important
}